
import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import { DOMParser } from "xmldom";
import CommentList from "../components/comment-list";
import CommentForm from "../components/comment-form";
const dictionary = require("../dic/dic.json");
// import BannerPost from "../components/banner-post" // ara mateix no es necessita (potser més endavant)
// import TopArticles from "../components/top-articles";
import Breadcrumb from "../components/breadcrumb";
const { htmlToText } = require("html-to-text");
// const languageIds = require("../dic/ids.json");

class AsesoriaTemplate extends Component {
  constructor (props) {
    super(props);
    this.state = { categoria: null, main_cat_slug: "", main_cat_name: "", breadcrumb: [], bannerPosts: "" };
    this.createSchema = this.createSchema.bind(this);
  }

  addScript (url) {
    const script = document.createElement("script");
    script.src = url;
    script.async = false;
    document.body.appendChild(script);
  }

  componentDidMount () {
    //this.addScript("https://code.jquery.com/jquery-3.6.0.min.js");
    this.addScript("/customjs/jquery-ui.min.js");
    this.addScript("/customjs/custom.js");
    this.addScript("/customjs/calculadoras/sueldo-neto.js");
    // delete  Te recomendamos
    const teRecomendamos = document.getElementsByClassName("rp4wp-related-posts");
    while (teRecomendamos.length > 0) {
      teRecomendamos[0].parentNode.removeChild(teRecomendamos[0]);
    }
    // Fin delete Te recomendamos
    const post = this.props.data.wpPost;
    let slugPage = post.uri;
    // const bannersContent = this.props.data.allWpPage.edges;
    // const bannerPosts = document.querySelectorAll(".banner-post");
    // const bannerIds = this.props.pageContext.bannerIds;
    // bannerPosts.forEach((bannerPost, index) => {
    //   if (index < bannerIds.length) {
    //     const bannerFound = bannersContent.filter((bannerContent) => bannerContent.node.databaseId === bannerIds[index]);
    //     if (bannerFound.length > 0) {
    //       bannerPost.innerHTML = bannerFound[0].node.page_content.contentRaw;
    //     }
    //   }
    // });
    const nodepostCat = post.categories.nodes;
    let categoria = this.state.categoria;
    let main_cat_slug = this.state.main_cat_slug;
    let main_cat_name = this.state.main_cat_name;
    // let bannersPosts = this.state.bannerPosts;
    // bannersPosts = bannerFound;
    for (const i in nodepostCat) {
      if (
        nodepostCat[i].ancestors !== undefined &&
        nodepostCat[i].ancestors !== null &&
        nodepostCat[i].databaseId !== 9525 &&
        nodepostCat[i].databaseId !== 9552 &&
        nodepostCat[i].databaseId !== 9528 &&
        nodepostCat[i].databaseId !== 9531 &&
        nodepostCat[i].databaseId !== 9534 &&
        nodepostCat[i].databaseId !== 9537 &&
        nodepostCat[i].databaseId !== 9549 &&
        nodepostCat[i].databaseId !== 9540 &&
        nodepostCat[i].databaseId !== 9543 &&
        nodepostCat[i].databaseId !== 9546
      ) {
        categoria = nodepostCat[i];
      } else if (nodepostCat[i].databaseId === 102 || nodepostCat[i].databaseId === 111) {
        main_cat_slug = nodepostCat[i].slug;
        main_cat_name = nodepostCat[i].name;
      }
    }
    const breadcrumb = [];

    let page = {
      title: main_cat_name,
      slug: main_cat_slug
    };
    // breadcrumb.push(page);
    // console.log(categoria);
    // if (categoria !== null) {
    //   console.log("entra");
    page = {
      title: "Asesorias",
      slug: ""
    };
    breadcrumb.push(page);
    //  }
    page = {
      title: post.title,
      slug: null
    };
    breadcrumb.push(page);
    this.setState({ main_cat_slug, main_cat_name, categoria, breadcrumb });
    // let checkH3=document.querySelectorAll("h3");
    // if(checkH3[0].classList.contains("p5")){
   
    
    // RelatedPosts delete las slash
    const relatedPostsWPML = document.querySelectorAll(".rp4wp-related-post-content a");
    relatedPostsWPML.forEach(link => {
      const href = link.getAttribute("href");
      if (href.slice(-1) === "/") {
        console.log(href, href.length);
        link.setAttribute("href", href.substring(0, href.length - 1));
      }
    });
  }

  removeScript (scriptToremove) {
    const allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute("src") !== null &&
        allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1
      ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  }

  componentWillUnmount () {
    // this.removeScript("https://code.jquery.com/jquery-3.6.0.min.js");
    this.removeScript("/customjs/jquery-ui.min.js");
    this.removeScript("/customjs/calculadoras/sueldo-neto.js");
    this.removeScript("/customjs/custom.js");
  }

  createSchema (seo, post, lang) {
    const nodepostCat = post.categories.nodes;
    let categoria = null;
    for (const i in nodepostCat) {
      if (
        nodepostCat[i].ancestors !== undefined &&
        nodepostCat[i].ancestors !== null &&
        nodepostCat[i].databaseId !== 9525 &&
        nodepostCat[i].databaseId !== 9552 &&
        nodepostCat[i].databaseId !== 9528 &&
        nodepostCat[i].databaseId !== 9531 &&
        nodepostCat[i].databaseId !== 9534 &&
        nodepostCat[i].databaseId !== 9537 &&
        nodepostCat[i].databaseId !== 9549 &&
        nodepostCat[i].databaseId !== 9540 &&
        nodepostCat[i].databaseId !== 9543 &&
        nodepostCat[i].databaseId !== 9546
      ) {
        categoria = nodepostCat[i];
      }
    }
    const appJsonAux = [];
    let urlImage = null;
    if (post.featuredImage !== null &&
      post.featuredImage.node !== null) {
      urlImage = post.featuredImage.node.sourceUrl;
    }
    let html = post.excerpt;
    html = html.replace("[", "");
    html = html.replace("]", "");
    const post_excerpt = htmlToText(html, {
      ignoreHref: true
    });
    const postSchema = {
      "@context": "https://schema.org",
      "@type": "Article",
      name: post.title,
      headline: post.title,
      description: post_excerpt,
      datePublished: post.date,
      dateModified: post.modified
    };
    if (urlImage !== null) {
      postSchema.image = urlImage;
    }

    if (categoria !== null) {
      postSchema.articleSection = categoria.name;
    }
    // afegir url autor
    if (post.author !== null) {
      let authorSchema = {
        "@type": "Person",
        name: post.author.node.name,
        image: post.author.node.avatar.url,
        url: "/" + lang + "/" + post.author.node.slug
      };
      // url
      if (post.author.node.name === "billage") {
        authorSchema = {
          "@type": "Organization",
          name: post.author.node.name,
          logo: "/images/logo-billage-color.svg"
        };
        // url
      }
      postSchema.author = authorSchema;
    }
    const postSchemaString = JSON.stringify(postSchema);

    appJsonAux.push(postSchemaString);
    // console.log("JSON", appJsonAux);

    return appJsonAux;
  }

  render () {
    const post = this.props.data.wpPost;
    const lang = this.props.pageContext.idioma;
    const destacados = this.props.data.wpCategory.posts.nodes;
    const contacto_answer = JSON.stringify(dictionary[lang].contacto);
    const seo = post.seo;
    let slugPage = post.uri;
    let app_json = this.createSchema(seo, post, lang);
    // Create faqs from h2

    if (typeof post.content !== "undefined" && post.content !== null) {
      const parser = new DOMParser();
      const domAux = parser.parseFromString(post.content, "text/html");
      let questions = [];
      const nodeList = domAux.childNodes;
      for (let i = 0; i < nodeList.length; i++) {
        const node = nodeList[i];
        if (node.tagName !== "h2") continue;
        let nextNode = node.nextSibling;
        if (nextNode.nodeName === "#text") nextNode = nextNode.nextSibling;
        if (nextNode.tagName !== "p") continue;
        if (nextNode.textContent.trim() === "") continue;
        const question = {
          "@type": "Question",
          name: node.textContent,
          acceptedAnswer: {
            "@type": "Answer",
            text: nextNode.textContent
          }
        };
        questions.push(question);
      }
      // var allContent = document.createElement("div");
      // allContent.innerHTML = post.content;
      // let questions = [];
      // allContent.querySelectorAll("h2").forEach((h2node) =>
      //   if(node.tagName !== "H2") return;
      //   let nextNode = node.nextSibling;
      //   if (nextNode.nodeName === "#text") nextNode = nextNode.nextSibling;
      //   if (nextNode.tagName !== "P") return;
      //   const question = {
      //     "@type": "Question",
      //     name: node.textContent,
      //     acceptedAnswer: {
      //       "@type": "Answer",
      //       text: nextNode.innerHTML
      //     }
      //   };
      //   questions.push(question);
      // });
      if (questions.length > 0) {
        const faqsSchema = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: questions
        };
        app_json.push(JSON.stringify(faqsSchema));
      }
    }

    // const alternates = [slugPage];
    // var openGraphslugPage = seo.opengraphUrl;
    /* PUBLICAR POSTS EN CATALÀ I ANGLÉS */
    const alternates = post.translated;
    let openGraphslugPage = seo.opengraphUrl;

    // const bannerContent = this.props.data.wpPage.page_content.contentRaw;
    // const bannerContent = this.state.BannerPost;
    let mainImage = null;
    if (post.featuredImage !== null &&
      post.featuredImage.node !== null &&
      post.featuredImage.node.localFile !== null) {
      mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
      // mainImage.aspectRatio= 16 / 9;
      mainImage.sizes = "(max-width: 540px) 100px,(max-width: 1024px) 400px, (max-width: 1200px) 300px, 800px";
    }

    if (slugPage !== undefined) {
      slugPage = slugPage.replace("https://www.getbillage.com/wp", "");
      slugPage = slugPage.replace("https://getbillage.com/wp", "");
      slugPage = slugPage.replace("https://www.getbillage.com", "");
      slugPage = slugPage.replace("http://www.getbillage.com/wp", "");
      slugPage = slugPage.replace("http://getbillage.com/wp", "");
      slugPage = slugPage.replace("http://www.getbillage.com", "");
      slugPage = slugPage.replace("/all/", `/${this.state.main_cat_slug}/`);
      slugPage = "/" + lang + "/" + slugPage.substring(4, slugPage.length);
      if (
        slugPage.charAt(slugPage.length - 1) === "/"
      ) {
        slugPage = slugPage.substring(0, slugPage.length - 1);
      }
    }

    openGraphslugPage = "/" + lang + "/" + openGraphslugPage.substring(4, openGraphslugPage.length);

    if (alternates !== undefined) {
      alternates.forEach((alternate) => {
        alternate.uri = alternate.uri.replace("https://getbillage.com/wp", "");
        alternate.uri = alternate.uri.replace("https://www.getbillage.com", "");
        alternate.uri = alternate.uri.replace("http://www.getbillage.com/wp", "");
        alternate.uri = alternate.uri.replace("http://getbillage.com/wp", "");
        alternate.uri = alternate.uri.replace("http://www.getbillage.com", "");
        const altLang = alternate.locale.locale.substring(0, 2);
        alternate.seo.canonical = "/" + altLang + "/" + alternate.uri.substring(4, alternate.uri.length);
        // alternate.seo.canonical = "/" + altLang + "/" + alternate.seo.canonical.substring(4, alternate.seo.canonical.length)
      });
    }

    // const relacionadosImportados =
    //   this.props.data.wpPost.relacionadosGroup.relacionados !== null
    //     ? this.props.data.wpPost.relacionadosGroup.relacionados
    //     : [];

    // const colPost = "col-12";
    // let colRelated = "d-none";
    // let foundDestacados = false;
    // if (destacados.length > 0 && lang === "es") {
    //   foundDestacados = true;
    // }
    // if (relacionadosImportados.length > 0 || foundDestacados) {
    //   colPost = "col-12 col-lg-8";
    //   colRelated = "col-12 col-lg-4";
    // }
    // var relacionadosImportados = this.props.data.allWpPost.edges;
    // const relacionados = [];
    // // console.log("Importados", relacionados);
    // for (const i in relacionadosImportados) {
    //   const relacionadoAux = relacionadosImportados[i];

    //   let relacionadoImage = null;
    //   if (relacionadoAux.featuredImage !== null &&
    //     relacionadoAux.featuredImage.node !== null &&
    //     relacionadoAux.featuredImage.node.localFile !== null) {
    //     relacionadoImage = relacionadoAux.featuredImage.node.localFile.childImageSharp.fluid;
    //     // mainImage.aspectRatio= 16 / 9;
    //     relacionadoImage.sizes = "(max-width: 1185px) 0px, 300px";
    //     relacionadoAux.relacionadoImage = relacionadoImage;
    //   }

    //   relacionados.push(relacionadoAux);
    // }
    const completeUrl = "https://www.getbillage.com" + slugPage;
    let ubicacion = null;
    if (post.ubicacion.ubicacion !== null) {
      ubicacion = post.ubicacion.ubicacion;
    }
    const industrias = [];
    if (post.industrias.industrias !== null) {
      post.industrias.industrias.forEach(industria => {
        if (!industrias.includes(industria)) {
          industrias.push(industria);
        }
      });
    }
    const servicios = [];
    if (post.asesorias.servicios !== null) {
      post.asesorias.servicios.forEach(servicio => {
        if (!servicios.includes(servicio)) {
          servicios.push(servicio);
        }
      });
    }
    let direccion = "";
    let telefono = "";
    let email = "";
    let web = "";
    if (post.asesoria_data !== null) {
      if(post.asesoria_data.direccion != null){
        direccion=post.asesoria_data.direccion;
      }
      if(post.asesoria_data.telefono != null){
        telefono=post.asesoria_data.telefono;
      }
      if(post.asesoria_data.email != null){
        email=post.asesoria_data.email;
      }
      if(post.asesoria_data.web != null){
        web=post.asesoria_data.web;
      }
    }
    return (
      <Layout langPage={lang} alternates={alternates}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugPage}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={post.date}
          dctermsModified={post.modified}
          lang={lang.substring(0, 2)}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : 300
          }
          alternates={alternates}
          appJson={app_json}
        />
        <div
          id="page-content"
          className="post-page intasesoria page-content"
          vocab="http://schema.org/"
          typeof="BlogPosting"
          resource={slugPage}
          data-contact={contacto_answer}
          data-lang="es"
        >
          <Breadcrumb props={this.state.breadcrumb} isChild="false" isPage="false" />
          <div className="wrapper wrapper-post container">
            <div className="postbody">
              <div className="row">
                <aside className="col-md-3 col-sm-4 col-12 asesoriadades">
                  {mainImage !== null
                    ? (
                        <Img itemProp="image"
                          fluid={
                            mainImage
                          }
                          alt={post.featuredImage.node.altText}
                        />
                      )
                    : null}
                  <div className="dades">
                  <strong className="title">Información de la empresa</strong>
                  {/* Teléfono */}
                  {telefono!==""?
                  (
                    <div className="telefono" dangerouslySetInnerHTML={{__html:"<a href='tel:"+telefono+"'>"+telefono+"</a>"}}></div>
                  )
                  :
                  null
                  }

                  {/* Web */}
                  {web!==""?
                  (
                    <div className="web" dangerouslySetInnerHTML={{__html:"<a href='"+web+"' target='_blank' rel='noopener noreferrer'>"+web+"</a>"}}></div>
                  )
                  :
                  null
                  }

                  {/* Ubicación */}
                  
                  <div className="ubi tipofiltre">
                    
                    <p>
                    {direccion !== "" ? (<span>{direccion} <br /></span>) : null}
                    {ubicacion !== null ? ubicacion : "No se ha encontrado ubicación"}
                    </p>
                  </div>
                  {/* Industrias */}
                  <div className="industries tipofiltre">
                  <h3 className="title">Industrias</h3>
                    {industrias.length !== 0
                      ? industrias.map(industria => {
                        return (
                          <p className="industrie" key={industria}>{industria}</p>
                        );
                      })
                      :
                      null
                    }
                  </div>
                  {/* Servicios */}
                  <div className="services tipofiltre">
                  <h3 className="title">Servicios</h3>
                    {servicios.length !== 0
                      ? servicios.map(servicio => {
                        return (
                          <p className="service" key={servicio}>{servicio}</p>
                        );
                      })
                      :
                      null
                      }
                  </div>
                  </div>
                </aside>
                <div className={"postcontent col-md-6 col-sm-8 col-12 fitxa"}>

                  <div className="innercontentpost">
                    <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                    {post.asesoria_partner.partner !== null && post.asesoria_partner.partner === "Partner" ? <small>Asesoria Partner</small> : null }
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
                <aside className="col-md-3 col-sm-12 col-12 formAsesoria sidebar">
                  <form type="POST" className="asesoria_ajax_form" type-form="asesorias" data-email={email}>
                    <big><strong className="title">Contacta con la asesoría</strong></big>
                   <label htmlFor="nombre">Tu nombre</label>
                    <input type="name" name="nombre" id="nombre" className="text_input is_empty" required="required"/>
                    <label htmlFor="email">Tu email</label>
                    <input type="email" name="email" className="text_input is_email" id="email" required="required"/>
                    <label htmlFor="phone">Tu teléfono</label>
                    <input type="name" name="phone" id="phone" className="text_input is_empty" required="required"/>
                    <textarea className="comentAsesoria text_area is_empty" name="message" defaultValue="" maxLength="250"></textarea>
                    <label htmlFor="checkbox-inline" className="checkbox-inline legal">
                      <input name="acepto_condiciones" id="checkbox-inline" type="checkbox"  className=""/>Acepto los términos y condiciones de Cegid Billage y el uso de datos personales como se explica en la política de privacidad de Cegid Billage.
                    </label>
                    <button type="submit" className="button" data-sending-label="Enviando">Enviar</button>
                  </form>
                </aside>
                </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AsesoriaTemplate;

export const asesoriaQuery = graphql`
  query currentAsesoriaQuery(
    $id: String!
  ) {
    wpPost(id: { eq: $id }) {
      title
      id
      databaseId
      content
      date(formatString: "D/MM/YYYY")
      modified
      uri
      excerpt
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      categories {
        nodes {
          databaseId
          name
          slug
          ancestors {
            nodes {
              slug
              name
            }
          }
        }
      }
      translated {
        slug
        uri
        locale{
          locale
        }
        seo {
          canonical
        }
      }
      featuredImage {
        node {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            url
          }
        }
      }
      comments {
        nodes {
          content
          date
          databaseId
          author {
            node {
              name
              email
              url
            }
          }
        }
      }
      author {
        node {
          name
          slug
          avatar {
            url
          }
        }
      }
      tags {
        nodes {
          name
          posts {
            nodes {
              databaseId
              title
              excerpt
              slug
              date(formatString: "DD/MM/yyyy")
              seo {
                title
                metaDesc
              }
              author {
                node {
                  name
                  slug
                  avatar {
                    url
                  }
                }
              }
              categories {
                nodes {
                  databaseId
                  name
                  slug
                  ancestors {
                    nodes {
                      databaseId
                      slug
                      uri
                      name
                    }
                  }
                }
              }
              featuredImage {
                node {
                  altText
                  srcSet
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      asesoria_partner {
        partner
        fieldGroupName
      }
      asesorias {
        servicios
        fieldGroupName
      }
      industrias {
        fieldGroupName
        industrias
      }
      ubicacion {
        ubicacion
        fieldGroupName
      }
      asesoria_data {
          direccion
          email
          telefono
          web
          fieldGroupName
        }
    }
    wpCategory(databaseId: { eq: 10857 }) {
      databaseId
      name
      slug
      posts {
        nodes {
          databaseId
          title
          slug
          featuredImage {
            node {
              srcSet
              sourceUrl
              localFile {
                childImageSharp {
                  fluid{
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
